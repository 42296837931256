#container, header#masthead, footer#footer {
  width: 1060px;
  margin: 0 auto;
}

.nav ul, nav ul, .grid ul, .slider ul, .accordion ul {
  list-style-type: none;
  display: block;
  padding: 0;
  margin: 0;
}
.nav li, nav li, .grid li, .slider li, .accordion li {
  display: block;
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font: normal 14px 'open_sansregular', sans-serif;
  background: #dbdbdb url("/images/bg.jpg") no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  letter-spacing: -0.020em;
  color: black;
}

a {
  color: #1b3298;
  transition: color 0.5s, background-color 0.5s;
}

#wrapper {
  position: relative;
  height: 100%;
}

#wrapper-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 100%);
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cccccc', endColorstr='#000000',GradientType=1 );
}

#container {
  background: #fff;
  padding: 50px 20px;
}

@font-face {
  font-family: 'open_sansregular';
  src: url("/fonts/OpenSans-Regular-webfont.eot");
  src: url("/fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans-Regular-webfont.woff") format("woff"), url("/fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansitalic';
  src: url("/fonts/OpenSans-Italic-webfont.eot");
  src: url("/fonts/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans-Italic-webfont.woff") format("woff"), url("/fonts/OpenSans-Italic-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Italic-webfont.svg#open_sansitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold';
  src: url("/fonts/OpenSans-Semibold-webfont.eot");
  src: url("/fonts/OpenSans-Semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/OpenSans-Semibold-webfont.woff") format("woff"), url("/fonts/OpenSans-Semibold-webfont.ttf") format("truetype"), url("/fonts/OpenSans-Semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'oswald_regular';
  src: url("/fonts/oswald-regular-webfont.eot");
  src: url("/fonts/oswald-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald-regular-webfont.woff") format("woff"), url("/fonts/oswald-regular-webfont.ttf") format("truetype"), url("/fonts/oswald-regular-webfont.svg#oswald_regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.nav li, nav li, .grid li {
  display: block;
  float: left;
}
.nav a, nav a, .grid a {
  display: block;
}

.img-holder {
  position: relative;
}

h1, h2, h3, h4 {
  font-weight: normal;
}

h1 {
  color: #1b3298;
  font: normal 25px "oswald_regular", sans-serif;
  text-transform: uppercase;
  margin-top: 0;
}

h2 {
  font-size: 1.429em;
  text-transform: uppercase;
}

b, strong {
  font-family: 'open_sanssemibold', sans-serif;
}

p.intro {
  font: normal 16px/1.313em 'open_sanssemibold', sans-serif;
  line-height: 1.45em;
}

p {
  line-height: 1.5em;
}

.home #container {
  padding-top: 0;
}

.home article.full-width {
  margin: 50px 0;
}

#home-slider {
  margin: -2px -20px 0;
}
#home-slider .slick-slide {
  position: relative;
}
#home-slider .img-holder {
  height: 335px;
}
#home-slider .caption {
  background: rgba(23, 43, 130, 0.9);
  font-size: 22px;
  line-height: 70px;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.client-projects article .box {
  padding: 12px 10px;
  margin-bottom: 5px;
}
.client-projects article .box:last-child {
  margin: 0;
}
.client-projects article .box h2 {
  font: 1.071em 'open_sanssemibold', sans-serif;
  text-transform: none;
  margin-top: 0;
}
.client-projects article .box .img-holder {
  width: 44.04%;
  height: 176px;
}
.client-projects article .box .description {
  width: 55.96%;
}

.project-banner {
  display: block;
  margin: 0;
  max-width: 100%;
}

header#masthead {
  padding: 0 20px;
  background: #fff url("/images/bg-header.png") no-repeat top right;
  border-bottom: 2px solid #273c9d;
}
header#masthead a.logo {
  background: url("/images/logo.png") no-repeat center;
  margin: 15px 0 12px 1px;
  color: transparent;
  display: block;
  line-height: 0;
  height: 126px;
  width: 180px;
  font-size: 0;
  float: left;
}

#header-contact {
  font-size: 15px;
  line-height: 17px;
  float: right;
  color: #1b3298;
  margin: 39px 0;
}
#header-contact a {
  font-size: 17px;
  font-family: 'open_sanssemibold', sans-serif;
}

nav#mainnav {
  margin-bottom: 17px;
  background: #1b3298;
  float: right;
}
nav#mainnav a {
  font: normal 15px/40px "oswald_regular", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.010em;
  position: relative;
  margin: 0 12px;
  padding: 0 2px;
  color: #fff;
}
nav#mainnav a:hover {
  color: #d1d6ea;
}
nav#mainnav a.active {
  background-color: #d1d6ea;
  padding: 0 12px;
  color: #1b3298;
  margin: 0;
}

a#mobile-menu {
  display: none;
  height: 15px;
  cursor: pointer;
  color: #fff;
  float: left;
  position: relative;
  z-index: 99999;
}
a#mobile-menu #burger {
  height: 3px;
  display: block;
  background: #1b3298;
  width: 26px;
  float: right;
}
a#mobile-menu #burger:before, a#mobile-menu #burger:after {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  content: '';
  height: 3px;
  display: block;
  background: #1b3298;
}
a#mobile-menu #burger:before {
  margin-top: 6px;
}
a#mobile-menu #burger:after {
  margin-top: 3px;
}
a#mobile-menu.active #burger {
  height: 0;
}
a#mobile-menu.active #burger:before {
  height: 5px;
  transform: rotate(45deg);
}
a#mobile-menu.active #burger:after {
  height: 5px;
  margin-top: -5px;
  transform: rotate(-45deg);
}

article {
  float: left;
  width: 66.66%;
  position: relative;
  padding-right: 30px;
}
article.full-width {
  float: none;
  width: 100%;
  width: auto;
  padding: 0 20px;
}

.grid ul {
  margin: 0 -5px;
}
.grid li {
  padding: 0 5px;
}
.grid.two li {
  width: 50%;
}

aside {
  float: left;
  width: 33.33%;
  position: relative;
}

footer#footer {
  padding: 19px 20px 15px;
  background: #1b3298;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
}
footer#footer a {
  color: #fff;
}
footer#footer a.logo {
  background: url("/images/logo-white.png") no-repeat center;
  margin: 0;
  width: 122px;
  height: 86px;
  color: transparent;
  line-height: 0;
  display: block;
  font-size: 0;
  float: left;
}
footer#footer .info {
  text-align: right;
  margin-top: 25px;
  float: right;
}
footer#footer #footer-contact {
  border-left: 1px solid #4759ac;
  margin-left: 40px;
  width: 200px;
}
footer#footer .netbop {
  float: right;
  font-size: 11px;
}

/*------------------------------------*\
    1. STRUCTURE
\*------------------------------------*/
address {
  font-style: normal;
}

[role="button"] {
  font: normal 18px/46px 'open_sanssemibold', sans-serif;
  background: #cc0000;
  color: #fff;
  text-align: center;
  display: block;
}
[role="button"]:hover {
  background-color: #e60000;
}

.img-holder {
  display: block;
}

.loader {
  background: #e8e9e9 url("/images/ajax-loader.gif") no-repeat center;
  position: absolute;
  z-index: 99999;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

/*------------------------------------*\
    4. CONTENT
\*------------------------------------*/
#accred-slider {
  margin-top: 26px;
}
#accred-slider .slick-slider {
  margin: 0 -5px;
}
#accred-slider .slick-slide {
  position: relative;
  padding: 0 5px;
}
#accred-slider img {
  display: block;
  max-width: 100%;
  margin: 0 auto 1px;
  border: 1px solid #ccc;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.6);
}

.accordion .accordion-header {
  background: #e6e6e6;
  padding: 0 20px;
  cursor: pointer;
}
.accordion h3 {
  font: normal 18px/35px "oswald_regular", sans-serif;
  float: left;
  margin: 0;
}
.accordion li {
  margin: 12px 0;
}

.accordion-content {
  padding: 0 20px;
  display: none;
}

.toggle-txt {
  color: #cc0000;
  font: normal 12px/35px 'open_sansregular', sans-serif;
  float: right;
  display: block;
  opacity: 1;
  transition: opacity 0.5s;
}
.toggle-txt:hover {
  color: #e60000;
}
.active .toggle-txt {
  opacity: 0;
}

#accreditations-grid .img-container, #accred-slider .img-container {
  overflow: hidden;
  border: 1px solid #e3e3e3;
  position: relative;
}
#accreditations-grid img, #accred-slider img {
  display: block;
  width: 100%;
  transition: 0.5s;
}
#accreditations-grid img:hover, #accred-slider img:hover {
  transform: scale(1.05);
}

#accreditations-grid {
  font-size: 24px;
}
#accreditations-grid ul {
  margin: 0 -12.5px;
}
#accreditations-grid li {
  padding: 0 12.5px;
  margin-bottom: 25px;
}

#quality-grid img {
  display: block;
  width: 100%;
  border: 2px solid #e5e5e5;
  font-size: 16px;
}
#quality-grid ul {
  margin: 0 -12.5px;
}
#quality-grid li {
  padding: 0 12.5px;
  margin-bottom: 25px;
}

.text-overlay {
  font-family: "oswald_regular", sans-serif;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 500;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
  color: white;
}
a:hover .text-overlay {
  opacity: 1;
}
.text-overlay p {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  right: 0;
  left: 0;
}

.box {
  display: block;
  background: #f0f0f0;
  font-size: 13px;
  line-height: 18px;
  color: black;
  padding: 20px;
}
.box h3 {
  font: normal 18px/35px "oswald_regular", sans-serif;
  text-transform: uppercase;
  background: #dbdbdb;
  padding: 0 20px;
  margin: -20px -20px 13px;
  letter-spacing: 0;
}
.project-page .box#project-box h3 {
  background-color: #1b3298;
  color: white;
}
.box h4 {
  font: normal 15px 'open_sanssemibold', sans-serif;
  margin: 0 0 10px;
}
.box .img-holder {
  width: 200px;
  height: 200px;
  float: left;
}
.box .img-holder img {
  transition: 2s;
  -moz-transition: 2s;
  -webkit-transition: 2s;
}
.box .description {
  float: left;
  width: 265px;
  padding-left: 20px;
}
.box:hover .img-holder img {
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.box .more {
  font: normal 11px 'open_sansitalic', sans-serif;
  display: block;
  color: #cc0000;
}
.box [role="button"] {
  margin: 4px 0;
}
.box .nav li, .box nav li, .box .grid li {
  float: none;
  margin-bottom: 1px;
}
.box .nav li:last-child, .box nav li:last-child, .box .grid li:last-child {
  margin: 0;
}
.box .nav a, .box nav a, .box .grid a {
  font: 15px/35px 'open_sanssemibold', sans-serif;
  background: #e3e3e3;
  color: black;
  padding: 0 25px;
  border-left: 4px solid #a7aecd;
  transition: 0.1s;
  -moz-transition: 0.1s;
  -webkit-transition: 0.1s;
}
.box .nav a:hover, .box nav a:hover, .box .grid a:hover {
  margin-left: -4px;
  background-color: #f0f0f0;
}
.box#services-box {
  padding: 0;
}
.box#services-box h3 {
  margin: 0 0 4px;
}

aside .box {
  margin-bottom: 10px;
}
aside .box .img-holder {
  float: none;
  height: 190px;
  width: 100%;
  width: auto;
  margin-bottom: 15px;
}
aside .box .description {
  padding: 0;
  width: 100%;
  width: auto;
  float: none;
}

td {
  padding: 0 10px;
}

/*------------------------------------*\
    5. FOOTER
\*------------------------------------*/
/*------------------------------------*\
    6. CONTACT FORM
\*------------------------------------*/
form fieldset {
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid #eee;
}

form fieldset label {
  font: normal 1em/33px 'open_sanssemibold', sans-serif;
  display: block;
  text-align: left;
  pointer-events: none;
  padding: 0 8px;
  color: #999;
  clear: both;
  margin-bottom: -33px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
}

form fieldset span.error {
  font: normal 10px/33px 'open_sanssemibold', sans-serif;
  color: #D01A1A;
  z-index: 80;
  float: right;
  bottom: 2px;
}

form fieldset input[type="submit"] {
  font: normal 1em/2.857em "oswald_regular", sans-serif;
  text-transform: uppercase;
  padding: 0 30px;
  color: #fff;
  background: #1b3298;
  width: auto;
  /*float: right;*/
  border: none;
  cursor: pointer;
  transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

form fieldset input[type="submit"]:active {
  transform: scale(0.95);
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
}

form fieldset input[type="submit"]:hover {
  background: #2741BB;
}

form fieldset textarea,
form fieldset input[type="tel"],
form fieldset input[type="text"],
form fieldset input[type="email"] {
  background: transparent;
  color: black;
  width: 100%;
  border: 2px solid #ccc;
  padding: 5px;
  display: block;
  position: relative;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  border-radius: 0;
  -webkit-appearance: none;
}

form fieldset textarea:focus,
form fieldset input[type="tel"]:focus,
form fieldset input[type="text"]:focus,
form fieldset input[type="email"]:focus {
  outline: none;
  border-color: #1b3298;
}

form fieldset textarea.focused,
form fieldset input[type="tel"].focused,
form fieldset input[type="text"].focused,
form fieldset input[type="email"].focused {
  border-color: #1b3298;
}

form fieldset label.focused {
  font-size: 0.857em;
  color: #1b3298;
  padding: 0;
  margin: 0;
}

form fieldset textarea {
  height: 98px;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

/*
.form-group.message, 
.form-group.captcha {
	float: left;
}
.form-group.message {
	padding-right: 15px;
	width: 429px;
}

.form-group.captcha {
	width: 220px;
}
.captcha input {
	margin-bottom: 15px;
}
.captcha-img {
	width: 100%;	
	background: #eee;
	padding: 5px;
}
.captcha-img img {
	display: block;
	margin: 0 auto;
	max-width: 100%;
}
.msg {
	border: 1px solid;
	padding: 10px;
	text-align: center;
	margin-bottom: 20px;
	-webkit-box-shadow: 0px 15px 5px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 15px 5px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 15px 5px -10px rgba(0,0,0,0.75);
}
.msg h3 {
	color: #fff;
	font-size: 2em;
	margin: 0;
}
.msg p {
	color: #fff;
	margin: 5px 0;
}
.msg.sent {
	border-color: #318b00;
	background: #66b346;
}
.msg.error {
	border-color: #911315;
	background: #C2191C;
}
*/
.map {
  border: 0;
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

/*------------------------------------*\
    7. MEDIA QUERIES
\*------------------------------------*/
/* DESKTOP -----------------
@media (max-width: 1040px) {
	header#masthead, #container, footer#footer {
		width: 970px;
	}
	.form-group.message {
		width: 369px;
	}
} */
/* TABLET (768px) ----------------- 
@media (max-width: 991px) {
	header#masthead, #container, footer#footer {
		width: 750px;
	}
	.form-group.message {
		width: 223px;
	}
} */
/* PHABLET (600px) ---------------- 
@media only screen and (max-width: 767px) {
  header#masthead, #container, footer#footer {
    width: 580px;
  }
  
  header#masthead {
    padding: 10px;
    background-position: center;
  }
    
  header#masthead a.logo {
    margin: 0 auto;
    float: none;
  }
  
  #header-contact {
    display: none;
  }
  
  a#mobile-menu {
    display: block;
  }
  nav#mainnav {
    position: absolute;
    height: 100%;
    width: 220px;
    left: -220px;
    top: 0;
    
    li {
      float: none;
      padding: 0;
      margin: 0;
    }
    
    a {
      padding: 0 10px;
      margin: 0;
      
      &.active {
        padding: 0 10px;
      }

      &:after {
        display: none;
      }
    }
    
  }
  aside,
  article {
    padding: 0;
    float: none;
    width: 100%;
  }
  
  article{
    margin-bottom: 10px;
  }
  
  .form-group.message {
    width: 320px;
  }
  
  .box .img-holder,
  .box .description {
    padding: 0;
    width: 100% !important;
    width: auto !important;
    float: none;
  }
  .box .img-holder {
    margin-bottom: 10px;
  }
} */
/* MOBILE (480px) ----------------- 
@media only screen and (max-width: 599px) {
  header#masthead, #container, footer#footer {
    width: auto;
  }
  
  .form-group.captcha,
  .form-group.message {
    padding: 0;
    width: 100%;
    width: auto;
    float: none;
  }
  
  
  
} */
/* MOBILE (320px) ----------------- 
@media only screen and (max-width: 479px) {
	header#masthead, #container, footer#footer {
		width: auto;
	}
}
*/
/*------------------------------------*\
    8. RETINA GRAPHICS
\*------------------------------------*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after,
header#masthead:before,
header#masthead:after,
#container:before,
#container:after,
footer#footer:before,
footer#footer:after,
section:before,
section:after,
.box:before,
.box:after,
.accordion-header:before,
.accordion-header:after,
ul:before,
ul:after {
  content: ' ';
  display: table;
}

.clearfix:after,
header#masthead:after,
#container:after,
footer#footer:after,
section:after,
.box:after,
.accordion-header:after,
ul:after {
  clear: both;
}

/*# sourceMappingURL=styles.min.css.map */
